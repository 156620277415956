import React, { useState } from "react"
import styled, { keyframes, css } from "styled-components"
import { Link } from "gatsby"
import { CaptionText, SubCaptionText } from "../typography/paragraph"
import QuickViewButton from "../buttons/quickViewButton"
import { getLocalizedString } from "../../utils/localization"
import { resizeImage } from "../../utils/image"
import { event as gaEvent } from "../../utils/analytics"
import { slug } from "../../utils/slug"

const cornerRadius = 10

const Card = styled.article`
  display: flex;
  flex-flow: column nowrap;
  width: calc(50vw - 40px);

  @media (min-width: 768px) {
    width: calc(20vw - 20px);
    display: block;
    flex-flow: unset;
  }
`
interface imageProps {
  loaded: boolean
}

const RecipeImage = styled.img<imageProps>`
  border-radius: ${cornerRadius}px ${cornerRadius}px 0px 0px;
  display: block;
  height: auto;
  align-self: center;
  width: 100%;
  visibility: ${props => (props.loaded ? "visible" : "hidden")};
  position: ${props => (props.loaded ? "" : "fixed")};
  z-index: ${props => (props.loaded ? "" : "-12")};
`

const RecipeInfo = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  border-radius: 0px 0px ${cornerRadius}px ${cornerRadius}px;
  height: 75px;
  padding: 0 5px;
  background: ${({ theme }) => theme.colors.white};

  @media (min-width: 1024px) {
    height: 66px;
  }
`

const RecipeTitle = styled(CaptionText)`
  align-self: flex-start;
  font-size: 12px;

  @media (min-width: 1024px) {
    font-size: 14px;
  }
`

const SubCaption = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`

const RecipeLink = styled(Link)`
  text-decoration: none;
`

const keyframesShimmer = keyframes`
  0% {
    background-position: -80vw 0;
  }
  100% {
    background-position: 80vw 0;
  }
`

const shimmerAnimation = css`
  background: linear-gradient(90deg, #e5e5e5 0%, #c6c6c6 35%, #e5e5e5 100%);
  background-size: 80vw 100%;
  animation: ${keyframesShimmer} 2.5s infinite linear;
`

const LoadingSubCaptionText = styled(SubCaptionText)`
  background-color: #e5e5e5;
  width: 16px;
  height: 12px;
  align-self: flex-end;
  ${shimmerAnimation}
`
const LoadingTitle = styled(RecipeTitle)`
  width: 80%;
  height: 16px;
  background-color: #e5e5e5;
  ${shimmerAnimation}
`

const LoadingImage = styled.svg`
  border-radius: ${cornerRadius}px ${cornerRadius}px 0px 0px;
  display: block;
  background-color: #e5e5e5;
  height: auto;
  width: 100%;
  align-self: center;
  ${shimmerAnimation}
`

interface Props {
  id: number
  recipe_status: any
  loadingRecipes: boolean
  image: string
  title: string
  cookTime: number
  credit?: string
  enableQuickView?: () => void
  disableQuickView?: boolean
}

const RecipeCard: React.FC<Props> = props => {
  const [isQuickViewVisible, setIsQuickViewVisible] = useState(false)
  const [imageLoaded, setImageLoaded] = useState(false)

  const loading = props.loadingRecipes
  const time = props.cookTime
  const hours = Math.floor(time / 60)
  const minutes = time % 60
  const sluggifiedTitle = slug(props.title)

  const sendClickEvent = () => {
    gaEvent({
      action: "Click",
      category: "Recipe Card",
      label: "Click on Recipe Card",
      value: props.recipe_status ? props.recipe_status.id : props.id,
    })
  }

  if (loading === true) {
    return (
      <Card>
        <LoadingImage
          width="300"
          height="400"
          xmlns="https://www.w3.org/2000/svg"
        ></LoadingImage>
        <RecipeInfo>
          <LoadingTitle> </LoadingTitle>
          <LoadingSubCaptionText></LoadingSubCaptionText>
        </RecipeInfo>
      </Card>
    )
  }

  return (
    <Card
      onMouseEnter={() =>
        props.disableQuickView ? null : setIsQuickViewVisible(true)
      }
      onMouseLeave={() =>
        props.disableQuickView ? null : setIsQuickViewVisible(false)
      }
    >
      {isQuickViewVisible && (
        <QuickViewButton
          onClick={e => {
            props.enableQuickView()
            gaEvent({
              category: "click",
              action: "quick_view",
              label: "quick_view",
              value: props.recipe_status ? props.recipe_status.id : props.id,
            })
          }}
        >
          {getLocalizedString("button.quickview")}
        </QuickViewButton>
      )}
      <Link to={`/recipes/${sluggifiedTitle}`}>
        <RecipeImage
          src={resizeImage(props.image)}
          loaded={imageLoaded}
          onLoad={() => {
            setImageLoaded(true)
          }}
        />
        {!imageLoaded ? (
          <LoadingImage
            width="300"
            height="400"
            xmlns="https://www.w3.org/2000/svg"
          ></LoadingImage>
        ) : (
          <></>
        )}
      </Link>
      <RecipeInfo>
        <RecipeLink to={`/recipes/${sluggifiedTitle}`}>
          <RecipeTitle onClick={sendClickEvent}>{props.title}</RecipeTitle>
        </RecipeLink>
        <SubCaption>
          <SubCaptionText>{props.credit}</SubCaptionText>
          <SubCaptionText>
            {hours > 0 ? hours + "h " + minutes + "m" : minutes + "m"}
          </SubCaptionText>
        </SubCaption>
      </RecipeInfo>
    </Card>
  )
}

RecipeCard.defaultProps = {
  disableQuickView: false,
}

export default RecipeCard
