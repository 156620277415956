import React from "react"
import styled from "styled-components"
import { Recipe } from "rily"
import { Text } from "../../../../components/typography/paragraph"

interface Props {
  description: Recipe["description"]
}

const DescriptionText = styled(Text)`
  margin-top: 30px;
  max-width: 800px;
`

const RecipeDescription: React.FC<Props> = ({ description }) => {
  return <DescriptionText>{description}</DescriptionText>
}

export default RecipeDescription
