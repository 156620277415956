import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import SEO from "../../components/seo/index"
import QuickViewPane from "../../components/quickviewPane"
import TwoPaneWrapper from "../../components/twoPaneWrapper"
import { MediumTitle } from "../../components/typography/title"
import { getLocalizedString } from "../../utils/localization"
import { resizeImage } from "../../utils/image"
import NotFoundPage from "../404"
import RecipeInstructions from "./components/recipeInstructions"
import RecipeDescription from "./components/recipeDescription"
import SimilarRecipes from "./components/similarRecipes"
import RecipeImages from "./components/recipeImages"
import { useWindowSize } from "../../hooks/useWindowSize"
import { Text } from "../../components/typography/paragraph"
import { slug } from "../../utils/slug"

const RecipeContainer = styled.div`
  display: flex;
  flex-direction: column;
`

interface Props {
  data: any
}

const RightPaneContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  @media (min-width: 768px) {
    padding: 55px 0 0;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 55px;
  margin-bottom: 60px;
`

const PaddedMediumTitle = styled(MediumTitle)`
  margin-top: 30px;
`

const Disclaimer = styled(Text)`
  color: lightgrey;
  font-size: 10px;
  margin: 20px;

  @media (min-width: 768px) {
    margin-left: 35%;
  }
`

const IndexPage: React.FC<Props> = ({ data }) => {
  const recipe = data.allRecipe.recipes[0].node
  const windowSize = useWindowSize()
  const [imagesToShow, setImagesToShow] = useState(
    windowSize?.width < 1000 ? 1.25 : 3.75
  )

  const [slidesToShow, setSlidesToShow] = useState(
    windowSize?.width < 1000 ? 1.25 : 4.25
  )

  useEffect(() => {
    const isMobile = windowSize?.width < 1000
    if (!isMobile) {
      setImagesToShow(3.75)
      setSlidesToShow(4.75)
    }

    if (isMobile) {
      setImagesToShow(1.25)
      setSlidesToShow(2.25)
    }
  }, [windowSize])

  // if we don't find the recipe for the passed ID, we 404
  if (!recipe) {
    return <NotFoundPage />
  }

  const resizedImages = recipe.images.map(image => resizeImage(image))

  const RightPane = () => (
    <RightPaneContainer>
      <RecipeImages images={resizedImages} slidesToShow={imagesToShow} />
      <RecipeDescription description={recipe.description} />
      <PaddedMediumTitle>
        {getLocalizedString("recipe.instructions")}
      </PaddedMediumTitle>
      <RecipeInstructions preparation={recipe.preparation} />
    </RightPaneContainer>
  )

  return (
    <RecipeContainer>
      <SEO
        title={recipe.title}
        description={recipe.description}
        pagePath={slug(recipe.title)}
        image={recipe.images[0]}
        recipe={recipe}
      />
      <TwoPaneWrapper
        left={
          <QuickViewPane
            recipe={recipe}
            hasCloseButton={false}
            hasViewInstructionsButton={false}
            hasTitle={false}
            navigateTo="/"
          />
        }
        right={<RightPane />}
      />

      <Row>
        {recipe.similarRecipes && (
          <>
            <MediumTitle>
              {getLocalizedString("recipe.similar_recipes")}
            </MediumTitle>
            <SimilarRecipes
              recipes={[
                ...recipe.similarRecipes,
                ...recipe.similarRecipes,
                ...recipe.similarRecipes,
                ...recipe.similarRecipes,
              ]}
              slidesToShow={slidesToShow}
            />
          </>
        )}
      </Row>
      <Disclaimer>
        Notice: Content at this site is not intended to prescribe, treat,
        mitigate, prevent, diagnose or cure any medical condition or its
        symptoms, which advice should only be obtained from a qualified health
        professional.
      </Disclaimer>
    </RecipeContainer>
  )
}

export const query = graphql`
  query RestRecipeQuery($id: String) {
    allRecipe(filter: { id: { eq: $id } }) {
      recipes: edges {
        node {
          author {
            id
            name
          }
          cookTime: cook_time
          cuisine
          description
          id
          images
          preparation
          title
          serving_size
          ingredients: recipe_ingredient {
            id
            preparation
            quantity
            unit
            data: ingredient_data {
              parent_id
              tags
              name: title
              id
            }
          }
          servingSize: serving_size
          recipe_status {
            id
            state
          }
        }
      }
    }
  }
`

export default IndexPage
