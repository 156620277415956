import React from "react"
import styled from "styled-components"

import Slider from "../../../../components/slider"

interface Props {
  images: Array<string>
  slidesToShow: number
}

const Image = styled.img`
  border-radius: 20px;
  box-sizing: border-box;

  padding: 0 10px;

  max-height: auto;
  max-width: 100%;
`

const RecipeImages: React.FC<Props> = ({ images, slidesToShow }) => {
  return (
    <div className={images.length < slidesToShow ? "slider-no-margin" : ""}>
      <Slider settings={{ slidesToShow }}>
        {images.map((slide, i) => (
          <Image src={slide} key={i} />
        ))}
      </Slider>
    </div>
  )
}

export default RecipeImages
