import React from "react"
import styled from "styled-components"
import { Recipe } from "rily"
import { PreparationStep } from "./components/PreperationText"
import { getLocalizedString } from "../../../../utils/localization"

interface Props {
  preparation: Recipe["preparation"]
}

const RecipePreparation = styled.ol`
  list-style: none;
  counter-reset: recipe-step-counter;

  margin-left: 20px; // just here for testing until we actually use component
`

const PreparationImage = styled.img`
  max-width: 100%;
  border-radius: 20px;
  margin-bottom: 30px;
`

const RecipeInstructions: React.FC<Props> = props => {
  const getPreperationStep = (
    step: string,
    stepCount: number,
    image?: string
  ) => {
    if (image) {
      return (
        <div key={stepCount}>
          <PreparationStep step={step} stepCount={stepCount} />
          <PreparationImage src={image} />
        </div>
      )
    }

    return <PreparationStep step={step} stepCount={stepCount} key={stepCount} />
  }
  return (
    <>
      <RecipePreparation>
        {props.preparation?.map((step, i) => getPreperationStep(step, i + 1))}
      </RecipePreparation>
    </>
  )
}

export default RecipeInstructions
