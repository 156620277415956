import React from "react"
import { SimilarRecipe } from "rily"
import Slider from "../../../../components/slider"
import RecipeCard from "../../../../components/recipeCard"
import "./slider.css"

interface Props {
  recipes: SimilarRecipe[]
  slidesToShow: number
}

const SimilarRecipes: React.FC<Props> = ({ recipes, slidesToShow }) => {
  return (
    <div className={recipes.length < slidesToShow ? "slider-no-margin" : ""}>
      <Slider settings={{ slidesToShow }}>
        {recipes?.map(({ title, cookTime, image, id }) => (
          <RecipeCard
            id={id}
            title={title}
            cookTime={cookTime}
            image={image}
            disableQuickView={true}
          />
        ))}
      </Slider>
    </div>
  )
}

export default SimilarRecipes
