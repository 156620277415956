import React from "react"
import styled from "styled-components"

const StyledButton = styled.button`
  background: ${({ theme }) => theme.colors.yellow};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  border: none;
  border-radius: 14px;
  box-sizing: border-box;
  padding: 5px 10px;
  width: 76px;
  height: 24px;
  font-family: ${({ theme }) => theme.fonts.neueHaasGrotesk};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.03em;
  text-align: left;
  position: absolute;
  top: 14px;
  left: -10px;
`

interface QuickViewButtonProps {
  children: string
  onClick?: (e?: any) => any
}

const QuickViewButton = ({
  children,
  onClick,
}: QuickViewButtonProps): JSX.Element => (
  <StyledButton onClick={onClick}>{children}</StyledButton>
)

export default QuickViewButton
