import React from "react"
import styled from "styled-components"
import { Text } from "../../../../../../components/typography/paragraph"

const PreperationStep = styled.li`
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
`

const PreperationText = styled(Text)`
  margin-left: 35px; // space between list number and text
  max-width: 700px;
`

interface Props {
  step: string
  stepCount: number
}

export const PreparationStep: React.FC<Props> = props => {
  return (
    <PreperationStep>
      <Text>{props.stepCount}</Text>
      <PreperationText>{props.step}</PreperationText>
    </PreperationStep>
  )
}
