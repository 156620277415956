import styled from "styled-components"

export const Pill = styled.button`
  height: 40px;
  width: 205px;
  box-sizing: border-box;
  border-radius: 20px;
  font-family: ${({ theme }) => theme.fonts.neueHaasGrotesk};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.03em;
  color: #000000;
  text-align: left;
  padding: 11.5px 15px;
  outline: none;
`
