import React, { useRef, useEffect, ReactNode } from "react"
import { debounce } from "lodash"

import Slick, { Settings } from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

interface Props {
  children: ReactNode
  settings?: Settings
}

const Slider: React.FC<Props> = ({ settings, children }) => {
  // const [isSliderUpdating, setIsSliderUpdating] = useState(false)
  const sliderRef = useRef<Slick>()

  const slideNext = () => {
    sliderRef?.current?.slickNext()
  }

  const slidePrev = () => {
    sliderRef?.current?.slickPrev()
  }

  const slide = (x: number): any => {
    x > 0 ? slideNext() : slidePrev()
  }

  useEffect(() => {
    window.addEventListener(
      "wheel",
      debounce(
        ({ deltaX }) => {
          slide(deltaX)
        },
        100,
        {
          leading: true,
          trailing: false,
        }
      )
    )
  })

  const getSettings = (): Settings => {
    return {
      dots: settings?.dots || false,
      infinite: settings?.infinite || false,
      speed: settings?.speed || 500,
      slidesToShow: settings?.slidesToShow || 3,
      slidesToScroll: settings?.slidesToScroll || 1,
      swipeToSlide: true,
      touchMove: true,
    }
  }
  return (
    <Slick {...getSettings()} className="overflow-hidden" ref={sliderRef}>
      {children}
    </Slick>
  )
}

export default Slider
