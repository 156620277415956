import React, { ReactNode } from "react"
import styled from "styled-components"
import { useWindowSize } from "../../hooks/useWindowSize"

interface Props {
  children?: ReactNode
  right: ReactNode
  left: ReactNode
}

const Wrapper = styled.section`
  width: 100%;
  @media (min-width: 768px) {
    display: flex;
    flex-flow: row no-wrap;
  }
`

const RightPane = styled.div`
  @media (min-width: 768px) {
    width: 65%;
  }
`

const LeftPane = styled.div`
  @media (min-width: 768px) {
    width: 35%;
  }
`

const TwoPaneWrapper: React.FC<Props> = ({ right, left }) => {
  const windowSize = useWindowSize()
  const isMobile = windowSize?.width < 768
  const isHomePage =
    typeof window !== "undefined" ? window.location.pathname === "/" : false

  return (
    <Wrapper>
      {(!isMobile || !isHomePage) && <LeftPane>{left}</LeftPane>}
      <RightPane>{right}</RightPane>
    </Wrapper>
  )
}

export default TwoPaneWrapper
