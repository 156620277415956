import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { Title, MediumTitle, TitleHeading } from "../typography/title"
import { Pill } from "../buttons/pill"
import { CaptionText, HeadingText } from "../typography/paragraph"
import { Recipe } from "rily"
import close from "../../static/images/close.svg"
import back from "../../static/images/back-arrow.svg"
import { DarkButton } from "../../components/buttons/darkButton"
import { getLocalizedString } from "../../utils/localization"
import { generateCookTimeString } from "../../utils/recipe"
import { slug } from "../../utils/slug"
import { event, selectContentevent } from "../../utils/analytics"

interface QuickViewPaneProps {
  recipe: Recipe
  hasCloseButton: boolean
  hasViewInstructionsButton: boolean
  hasTitle: boolean
  navigateTo?: string
  enableQuickView?: (props: any) => any
}

const InstructionButton = styled(DarkButton)`
  border: none;
  position: sticky;
  top: 25px;
  margin-bottom: 20px;
`

const QuickViewPane = ({
  recipe,
  enableQuickView,
  hasCloseButton,
  hasViewInstructionsButton,
  hasTitle,
  navigateTo,
}: QuickViewPaneProps) => {
  let ingredientsList = recipe.ingredients
  const ingredients = ingredientsList.map((item, i) => {
    return (
      <IngredientPill key={i}>
        <Flex>
          <Measurement>
            {item.quantity} {item.unit}
          </Measurement>
          <Ingredient>
            {item.data.name.trim()} <Details>{item.preparation}</Details>
          </Ingredient>
        </Flex>
      </IngredientPill>
    )
  })

  const urlSafeAuthorName = recipe?.author?.name
    ? slug(recipe?.author?.name)
    : ""

  const urlSafeRecipeTitle = recipe?.title ? slug(recipe?.title) : ""

  const servingSize =
    recipe?.serving_size < 10 ? `0${recipe.serving_size}` : recipe.serving_size

  return (
    <LeftWrapper>
      <Heading>
        {hasTitle && (
          <SmallHeading>{getLocalizedString("quickview.recipe")}</SmallHeading>
        )}
        {hasCloseButton && (
          // Sets null as quickViewRecipe on state, hiding the QuickViewPane component
          <Close src={close} onClick={() => enableQuickView(null)} />
        )}

        <Navbar>
          {!!navigateTo && (
            <Link to={navigateTo}>
              <BackIcon src={back} />
            </Link>
          )}

          {!!recipe.cuisine && !hasCloseButton && (
            <Breadcrumb>{`Recipes / Cuisine / ${recipe.cuisine}`}</Breadcrumb>
          )}
        </Navbar>
      </Heading>

      <RecipeTitle>{recipe.title}</RecipeTitle>
      <SmallHeading>
        Recipe by{" "}
        <Link
          to={`/author/${urlSafeAuthorName}`}
          onClick={() =>
            selectContentevent({
              content_type: "recipe_detail_author",
              item_id: recipe?.author?.id,
            })
          }
        >
          {recipe?.author?.name}
        </Link>
        <br />
        <br />
      </SmallHeading>
      <RoundedBoxWrapper>
        <RoundedBox>
          <SmallHeading>
            {getLocalizedString("quickview.ingredients")}
          </SmallHeading>
          <Value>{recipe.ingredients.length}</Value>
        </RoundedBox>
        <RoundedBox>
          <SmallHeading>{getLocalizedString("quickview.time")}</SmallHeading>
          <Value>{generateCookTimeString(recipe.cookTime)}</Value>
        </RoundedBox>
      </RoundedBoxWrapper>
      {hasViewInstructionsButton && (
        <Link to={`/recipes/${urlSafeRecipeTitle}`}>
          <InstructionButton
            onClick={() => {
              event({
                category: "click",
                action: "quick_view",
                label: "view_instructions",
                value: recipe.recipe_status.id,
              })
            }}
          >
            {getLocalizedString("quickview.view_instructions")}
          </InstructionButton>
        </Link>
      )}
      <MediumTitle>Ingredients</MediumTitle>
      {recipe?.serving_size && (
        <CaptionText>
          Serves <u>{servingSize}</u>
        </CaptionText>
      )}
      {ingredients}
    </LeftWrapper>
  )
}

QuickViewPane.defaultProps = {
  hasCloseButton: true,
  hasViewInstructionsButton: true,
  hasTitle: true,
}

export default QuickViewPane

const LeftWrapper = styled.div`
  padding: 8px;
  position: relative;

  @media (min-width: 375px) {
    padding: 15px;
  }
  @media (min-width: 768px) {
    padding: 30px 23px 30px 55px;
    height: 100%;
    min-height: calc(100vh - 60px);
  }
`

const IngredientPill = styled(Pill)`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.platinum};
  margin: 5px 10px 5px 0;
  min-height: 20px;
  height: fit-content;
  width: 100%;
  cursor: initial;
  @media (min-width: 550px) {
    width: calc(50% - 10px);
  }
  @media (min-width: 768px) {
    width: 100%;
    max-width: 368px;
    margin: 5px 20px 5px 0;
  }
`

const Flex = styled.div`
  display: flex;
`

const Heading = styled(Flex)`
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 4px;
`

const SmallHeading = styled(CaptionText)`
  color: ${props => props.theme.colors.gray};
  margin-bottom: 6px;
  a {
    all: unset;
    color: ${props => props.theme.colors.black};
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  a:visited {
    color: ${props => props.theme.colors.black};
  }
`

const RecipeTitle = styled(TitleHeading)`
  color: ${({ theme }) => theme.colors.black};
  font-size: 25px;
  line-height: 28.67px;
  margin-bottom: 20px;
`

const RoundedBoxWrapper = styled(Flex)`
  margin-bottom: 37px;
`

const RoundedBox = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.platinum};
  box-sizing: border-box;
  border-radius: 10px;
  min-width: 105px;
  height: 70px;
  padding: 10px 15px;
  margin-right: 10px;
`

const Value = styled(HeadingText)`
  color: ${({ theme }) => theme.colors.yellow};
`

const Details = styled.span`
  color: ${({ theme }) => theme.colors.gray};
  line-height: 16px;
  text-transform: none;
`

const Measurement = styled.span`
  width: 82px;
`

const Ingredient = styled.p`
  text-transform: capitalize;
  line-height: 17px;
`

const Close = styled.img`
  cursor: pointer;
  right: 20px;
  position: absolute;
`

const BackIcon = styled.img`
  cursor: pointer;
  margin-bottom: 10px;
  width: 26px;
`

const Breadcrumb = styled.div`
  margin-bottom: 30px;
  font-family: ${({ theme }) => theme.fonts.neueHaasGrotesk};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.03em;
  text-align: left;
`

const Navbar = styled.div`
  display: flex;
  flex-direction: column;
`
